import * as Sentry from '@sentry/react';
import { Ticker } from '@createjs/core';
import { Stage, Shape, Touch } from '@createjs/easeljs';
import { Tween, Ease } from '@createjs/tweenjs';
import React, { useEffect, useRef } from 'react';

import './App.scss';

function Background() {

  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;

    const stage = new Stage(canvas);

    Touch.enable(stage);

    let windowWidth = window.innerWidth;
    let windowHeight = document.body.offsetHeight;

    let hexSize = 25
    let hexWidth = Math.sqrt(3) / 2 * hexSize;
    let hexHeight = 3 / 4 * 2 * hexSize;

    let numRows = Math.floor(windowHeight / hexWidth);
    let numColumns = Math.floor(windowWidth / hexHeight);

    let activeStageCount = 0;

    stage.canvas.width = windowWidth;
    stage.canvas.height = windowHeight;
    stage.enableMouseOver(30);
    Ticker.framerate = 60;

    const rand = (low, high) => Math.floor(Math.random() * (high - low + 1) + low);
    const tweenComplete = () => activeStageCount--;
    const init = () => {
      for(let row = 0; row < numRows; row++) {
        for(let column = 0; column < numColumns; column++) {
          const hexagon = new Shape();
          const x = (hexWidth * 2) * column + ((row % 2) * hexWidth);
          const y = hexHeight * row;
          hexagon
            .graphics
            .beginFill(`rgb(0, 0, ${rand(100, 255)})`)
            .drawPolyStar(x, y, hexSize, 6, 0, 90);
          stage.addChild(hexagon);
        }
      }
      stage.update();
    };
    const animate = (e) => {
        Tween.get(e.target, {override: true})
            .to({y: -hexSize / 4}, 250, Ease.linear)
            .call(() => {
                Tween.get(e.target, { override: true }).wait(1000).to({ y: 0 }, 250, Ease.linear).call(tweenComplete);
            });
        activeStageCount++;
    };

    stage.addEventListener('mouseover', animate);
    stage.addEventListener('touchmove', animate);

    Ticker.addEventListener("tick", (e) => {
      //Only update if animations are waiting to be updated to save us from constantly re-rendering
        if(activeStageCount) {
            stage.update(e)
        }
    });
    let debounce = null;
    window.addEventListener('resize', () => {
        if(debounce !== null) {
            clearTimeout(debounce);
        }
        debounce = setTimeout(() => {
            windowWidth = window.innerWidth;
            windowHeight = document.body.offsetHeight;
            numRows = Math.floor(windowHeight / hexWidth);
            numColumns = Math.floor(windowWidth / hexHeight);
            stage.canvas.width = windowWidth;
            stage.canvas.height = windowHeight;
            stage.removeAllChildren();
            init();
        }, 100);
    });

    init();
  }, []);

  return (
    <canvas id='canvas' ref={canvasRef}></canvas>
  );
}

function App() {

  return (
    <div className="App">
      <Background />
      <section className='main'>
        <h1>Benjamin Fortune</h1>
        <h2>Web Developer and lover of all things JavaScript.</h2>
        <ul className='links'>
          <li><a href='https://github.com/xbenjii' target='_blank' rel='noreferrer'><i className='fa fa-github fa-2x faa-shake animated-hover'></i></a></li>
          <li><a href='https://www.facebook.com/xbenjiidotcodotuk' target='_blank' rel='noreferrer'><i className='fa fa-facebook-official fa-2x faa-shake animated-hover'></i></a></li>
          <li><a href='http://stackoverflow.com/users/2615209/ben-fortune' target='_blank' rel='noreferrer'><i className='fa fa-stack-overflow fa-2x faa-shake animated-hover'></i></a></li>
          <li><a href='mailto:hello@xbenjii.co.uk' target='_blank' rel='noreferrer'><i className='fa fa-envelope fa-2x faa-shake animated-hover'></i></a></li>
        </ul>
      </section>
    </div>
  );
}

export default Sentry.withProfiler(App);
